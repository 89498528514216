import "./video-player.scss";

import { useEffect, useState } from "react";

const VideoPlayer = ({ webm, mp4, fallback }) => {
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="video-wrapper">
      <video
        className={`video ${loaded && playing ? "visible" : "hidden"}`}
        autoPlay
        loop
        controls={false}
        playsInline
        muted
        onPlay={() => setPlaying(true)}
        aria-label="video player"
        role="region"
      >
        <source src={webm} type="video/webm" />
        <source src={mp4} type="video/mp4" />
        <p>Your browser does not support this video element 😕</p>
      </video>
      <img
        className={`poster ${loaded && playing ? "hidden" : "visible"}`}
        src={fallback}
        alt=""
        width="800"
        height="800"
        aria-label="video poster"
      />
    </div>
  );
};

VideoPlayer.defaultProps = {
  fallback: "",
};

export default VideoPlayer;
