import "./footer.scss"

import { ReactComponent as HeartIcon }from "../../img/icons/heart.svg"
import { ReactComponent as ReactIcon }from "../../img/icons/react.svg"
import Socials from "../../components/socials/Socials"
import { words } from '../../data/words'

const Footer = () => {

    const year = new Date().getFullYear()

    return (
        <div className="footer-wrapper">
            <p className="message-1">{words.ctaTitle}</p>
            <p className="message-2">{words.ctaDescription}</p>
            < Socials />
            <div className="footer">
                <div>
                    <h2>Built with </h2>
                    <div className="icon-wrapper">
                        <ReactIcon className="footer-svg spin" title="React" />
                    </div>
                    <h2>and</h2>
                    <div className="icon-wrapper">
                        <HeartIcon className="footer-svg" title="Love" />
                    </div>
                </div>
                <p>© {year} {words.name}</p>
            </div>
        </div>
    )
}

export default Footer
