import "./socials.scss"

// Icons from fontAwesome
import Github from "../../img/icons/github.svg"
import Twitter from "../../img/icons/twitter.svg"
import Linkedin from "../../img/icons/linkedin.svg"

const links = [
    {
        title: 'Github',
        icon: Github,
        url: 'https://github.com/alexleybourne',
    },
    {
        title: 'Twitter',
        icon: Twitter,
        url: 'https://twitter.com/AlexLeybourne',
    },
    {
        title: 'Linkedin',
        icon: Linkedin,
        url: 'https://www.linkedin.com/in/alexleybourne/',
    },
]

const Socials = (props) => {
    return (
        <div className="socials invert-for-dark-mode">
            {links.map((link, index) =>
                <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="hover"
                    title={link.title}
                >
                    <img src={link.icon} alt={link.title} />
                </a>
            )}
        </div>
    )
}

export default Socials
