export const words = {
  intro: 'Hello, My name is',
  name: 'Alex Leybourne',
  //   Keep the scrolling words array to a length of 8
  scrollingWords: [
    'Web Wizard',
    'Pixel Perfectionist',
    'Dark Mode Toggler',
    'UI/UX Designer',
    'VR Goggler',
    'Javascript Jedi',
    'Slack Emoji Guru',
    'Creative Innovator',
  ],
  description:
    'I am a web developer with a passion for innovation and creating modern, beautiful and cutting edge digital experiences.',
  aboutTitle: 'About Me',
  aboutDescription: `I first stepped into the world of web development as a video editor and social media manager. Always wanting to customise sites and show off my work in exciting and creative ways, I was soon following HTML and CSS tutorials and quickly fell in love with it. Shortly after I decided to pivot my career and completed an intensive web development boot-camp and I haven't looked back since. My favourite language is JavaScript and my weapon of choice is currently Next.js. I am proficient in many other frameworks and Languages such as React, React Native, Vue, Scss, Html and Node to name a few.`,
  projectsTitle: 'Projects',
  ctaTitle: 'Like what you see?',
  ctaDescription: 'Please feel free to get in touch and say hello!',
};
