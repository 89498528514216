import "./split-text.scss"

// import { useState } from "react"

import SplitText from "../splitText/SplitText"
import { motion, AnimatePresence } from "framer-motion"

const TextAnimation = (props) => {

    // const [visible, setVisible] = useState(true)

    return (
        <AnimatePresence>
            {props.visible && (
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <SplitText
                        initial={{ y: "100%" }}
                        animate="visible"
                        variants={{
                            visible: (i) => ({
                                y: 0,
                                transition: {
                                    ease: "circOut",
                                    delay: i * 0.02 + props.duration / 2,
                                    duration: props.duration
                                }
                            })
                        }}
                    >
                        {props.text}
                    </SplitText>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default TextAnimation
