import "./darkModeToggle.scss"
import { useState, useEffect } from 'react';

const DarkModeToggle = (props) => {

    const [ darkMode, setDarkMode ] = useState(props.darkMode)

    useEffect(() => {
    if (props.darkMode !== darkMode) {
        setDarkMode(props.darkMode);
    }
    }, [props.darkMode, darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    return (
        <div
            className="dark-mode-toggle-container invert-for-dark-mode"
            onClick={toggleDarkMode}
        >
            <div className={`circle-outline ${darkMode ? "solid" : "dotted"}`}>
                <div className={`circle ${darkMode ? "moon" : "sun"}`} />
            </div>
        </div>
    )
}

export default DarkModeToggle
