import "./intro.scss"

import { AnimatePresence, motion } from "framer-motion"

import Fallback from "../../img/Memoji-Intro-Fallback-Compressed.jpg"
import Mp4 from "../../video/Memoji-Intro.mp4"
import TextAnimation from "../../components/textAnimation/TextAnimation"
import VideoPlayer from "../../components/videoPlayer/VideoPlayer"
import Webm from "../../video/Memoji-Intro.webm"
import {words} from "../../data/words"

const Intro = () => {
    return (
        <AnimatePresence>
            <div className="intro">
                <div className="left">
                    <div className="wrapper">
                        <p className="intro-text">
                            <TextAnimation
                                visible={true}
                                duration={1}
                                text={words.intro}
                            />
                        </p>
                        <h1 className="name">
                            <TextAnimation
                                visible={true}
                                duration={1.4}
                                text={words.name}
                            />
                        </h1>
                        <motion.div
                            className="title"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1.4 }}
                        >
                            {/* Keep this to 8 items */}
                            <div className="title-wrapper theme-colour-text"> 
                                { words.scrollingWords.map((word, i) => <div key={i} className="item">{word}</div>)}
                            </div>
                        </motion.div>
                        <p className="description">
                            <TextAnimation
                                visible={true}
                                duration={1.2}
                                text={words.description}
                            />
                        </p>
                    </div>
                    {/* Hide the scroll animation for mobile temp fix */}
                    { window.innerWidth > 900 && <motion.div 
                        className="scroll-icon invert-for-dark-mode"
                        initial={{ 
                            opacity: 0,
                            translateY: '42vh',
                        }}
                        animate={{ 
                            opacity: 1,
                            translateY: '40vh',
                        }}
                        transition={{
                            duration: 1,
                            delay: 4,
                        }}
                    >
                        <div className="dot" />
                    </motion.div> }
                </div>
                <motion.div
                    className="right"
                    initial={{ 
                        opacity: 0,
                        // scale: 0.1
                    }}
                    animate={{ 
                        opacity: 1,
                        // scale: 1, 
                    }}
                    transition={{ 
                        type: "spring",
                        stiffness: 100,
                        duration: 1,
                        delay: 1,
                    }}
                >
                    <div className="bg theme-colour-background">
                        <VideoPlayer
                            mp4={Mp4}
                            webm={Webm}
                            fallback={Fallback}
                        />
                    </div>
                </motion.div>
            </div>
        </AnimatePresence>
    )
}

export default Intro
