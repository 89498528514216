import "./projects.scss"

import GithubGrid from "../../components/githubGrid/GithubGrid"
import ProjectStack from "../../components/projectStack/ProjectStack"
import useInView from "react-cool-inview"
import { words } from '../../data/words'

const Projects = () => {

    const { observe, inView } = useInView({
        // Stop observe when the target enters the viewport, so the "inView" only triggered once
        unobserveOnEnter: true,
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        rootMargin: "-200px 0px",
    });

    return (
        <>
            <div className={`projects ${inView ? "visible" : "hidden"}`} ref={observe}>
                <div className="top">
                    <h1 className={`delay-1 ${inView ? "visible" : "hidden"}`}>{words.projectsTitle}</h1>
                    <div className="grid-wrapper">
                        { inView ? <GithubGrid /> : <div className="empty-grid-div"></div> }
                    </div>
                </div>
            </div>
            <ProjectStack />
        </>
    )
}

export default Projects
