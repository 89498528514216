import "./github-grid.scss"
import { useEffect, useState } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions';

const GithubGrid = () => {

    const [created, setCreated] = useState(false);
    // const [cleared, setCleared] = useState(false);
    const { width } = useWindowDimensions();

    const isMobile = width < 901;
    const maxSquares = isMobile ? 106 : 232;

    // Hidden Squares
    const mobileHiddenSquares = [25, 31, 32, 33, 38, 39, 40, 41, 45, 46, 47, 52, 53, 54, 58, 59, 60, 61, 66, 67, 68, 73, 74, 75, 81];
    const desktopHiddenSquares = [88, 94, 95, 96, 101, 102, 103, 108, 109, 110, 115, 116, 117, 121, 122, 123, 124, 129, 130, 131, 136, 137, 138, 143, 146];
    const hiddenSquares = isMobile ? mobileHiddenSquares : desktopHiddenSquares;

    // Hide squares more on the edges on desktop
    const hideSquares = (i, level) => {
        if ((i < 28 && level > 1) || (i > 210 && level > 1)) {
            return 'hidden';
        }
        if ((i < 42 && level > 2) || (i > 196 && level > 2)) {
            return 0;
        }
         if ((i < 63 && level > 3) || (i > 175 && level > 2)) {
            return 0;
        }
        return level;
    }

    // const clearGrid = () => {
    //     const squares = document?.querySelector('.squares');
    //     squares.innerHTML = '';
    //     setCreated(false);
    // }

    const createGrid = () => {
        setTimeout(function () {
            setCreated(true);
            const squares = document?.querySelector('.squares');
            for (var i = 1; i < maxSquares; i++) {
                const level = Math.floor(Math.random() * 5);
                squares.insertAdjacentHTML('beforeend', `
                    <li class="level-${hiddenSquares.includes(i) ? 0 : isMobile ? level : hideSquares(i, level)} commit-square-${i}" style="animation-delay:${i/300}s;"></li>
                `);
            }
        }, 100);
    }
    
    // Regen on window resize
    // if (width > 900 && created ) { 
    //     setCleared(true);
    //     clearGrid();
    //     createGrid();
    // };

    useEffect(() => {
        if (!created) {
            createGrid();
        }
    })

    // Thanks to AderinoKun for some of this code
    // https://codepen.io/ire/pen/Legmwo

    return (
        <div className="graph">
            <ul className="squares">
            {/* Added via Javascript */}
            </ul>
        </div>
    )
}

export default GithubGrid
