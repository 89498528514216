import "./about.scss"

import CssLogo from "../../img/logos/css.svg"
import Fallback from "../../img/Memoji-About-Fallback-Compressed.jpg"
// import Fallback from "../../video/Memoji-About.gif"
import GitLogo from "../../img/logos/git.svg"
import HtmlLogo from "../../img/logos/html5.svg"
import JavascriptLogo from "../../img/logos/java-script.svg"
import Macbook from "../../img/Macbook-Compressed.png"
import Mp4 from "../../video/Memoji-About.mp4"
import NextLogo from "../../img/logos/next.svg"
import ReactLogo from "../../img/logos/react.svg"
import SassLogo from "../../img/logos/sass.svg"
import Socials from "../../components/socials/Socials"
import TextAnimation from "../../components/textAnimation/TextAnimation"
import VideoPlayer from "../../components/videoPlayer/VideoPlayer"
import VueLogo from "../../img/logos/vuejs.svg"
import Webm from "../../video/Memoji-About.webm"
import useInView from "react-cool-inview"
import {words} from "../../data/words"

const About = () => {

    const { observe, inView } = useInView({
        // Stop observe when the target enters the viewport, so the "inView" only triggered once
        unobserveOnEnter: true,
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        rootMargin: "-200px 0px",
    });

    return (
        <div className="about" ref={observe}>
            <div
                className={`left ${inView ? "visible" : "hidden"}`}
            >
                <div className="window theme-colour-border">
                    <div className="dot-wrapper dark-mode-background">
                        <div className="dot theme-colour-background"/>
                        <div className="dot theme-colour-background"/>
                        <div className="dot theme-colour-background"/>
                    </div>
                    <div className="fill theme-colour-background">
                        <img
                            className="laptop-image"
                            src={Macbook}
                            alt="Macbook"
                        />
                        <VideoPlayer
                            mp4={Mp4}
                            webm={Webm}
                            fallback={Fallback}
                        />
                        <div className="logos">
                            <img src={GitLogo} alt="Git Logo" />
                            <img className="react" src={ReactLogo} alt="React Logo" />
                            <img src={JavascriptLogo} alt="Javascript Logo" />
                            <img src={CssLogo} alt="Css 3 Logo" />
                            <img src={HtmlLogo} alt="Html 5 Logo" />
                            <img src={VueLogo} alt="Vue Logo" />
                            <img src={NextLogo} alt="Next Logo" />
                            <img src={SassLogo} alt="Sass Logo" />
                        </div>
                        
                    </div>
                    <div className="window-bg"/>
                </div>
            </div>
            <div className="right">
                <h1 className="title">
                    <TextAnimation
                        visible={inView}
                        duration={1}
                        text={words.aboutTitle}
                    />
                </h1>
                <span className="sub">
                    <TextAnimation
                        visible={inView}
                        duration={1}
                        text={words.aboutDescription}
                    />
                </span>
                <div className={`delay-4 ${inView ? "visible" : "hidden"}`}>
                    <Socials/>
                </div>
                
            </div>
        </div>
    )
}

export default About
