import './iconLink.scss';

import Link from '../../img/icons/link.svg'
import Github from '../../img/icons/github.svg'
import Twitter from '../../img/icons/twitter.svg'
import Linkedin from '../../img/icons/linkedin.svg'

const IconLink = ({
  key,
  link
}) => {

  // Finds and matches an icon based on icon source name
  const icons = [Link, Github, Twitter, Linkedin];
  const imgSrc = icons.find(i => i.toString().includes(link.icon.toLowerCase()));

  return (
    <a
      key={key}
      href={link.url}
      target="_blank"
      rel="noreferrer"
      className="link-icon invert-for-dark-mode"
      title={link.title}
    >
      <img className="link-img" src={imgSrc} alt={link.title} />
      <p className="link-text">{link.title}</p>
    </a>
  );
};

export default IconLink
