import "./app.scss"

import { useState, useEffect } from 'react';

import DarkModeToggle from "./components/darkModeToggle/DarkModeToggle"
import Intro from "./pages/intro/Intro"
import About from "./pages/about/About"
import Projects from "./pages/projects/Projects"
import Footer from "./components/footer/Footer"

// For Debugging
const allowConsoleClear = true;

// Nice Ascii art tool 🖼
// https://onlineasciitools.com/convert-text-to-ascii-art

const d = new Date();

const loadMessage = `
%c    :::     :::        :::::::::: :::    :::     :::        
  :+: :+:   :+:        :+:        :+:    :+:     :+:        
 +:+   +:+  +:+        +:+         +:+  +:+      +:+        
+#++:++#++: +#+        +#++:++#     +#++:+       +#+        
+#+     +#+ +#+        +#+         +#+  +#+      +#+        
#+#     #+# #+#        #+#        #+#    #+#     #+#        
###     ### ########## ########## ###    ###     ##########

%c© ${d.getFullYear()} Alex Leybourne.
`;
const loadMessagePost = () => console.log(loadMessage, 'color: #4B9CF1', 'font-weight:bold');

const App = () => {
  // Post on load
  useEffect(() => {
    if (allowConsoleClear) console.clear();
    loadMessagePost();
  }, []);

  // This code can definitely be improved
  // It checks for you system preference / theme ( dark mode on or off ) on load and when it's changed to update the scheme
  // However if you toggle it yourself that will be saved and used as preference

  // Checking user prefence for darkMode
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const darkModePreferences = () => {
    // Checks if we have already saved a preference if not it gets the system preference and saves it
    if (localStorage.getItem('darkMode') === null) {
      localStorage.setItem('darkMode', prefersDarkMode);
      return prefersDarkMode;
    } else {
      return localStorage.getItem('darkMode') === 'true' ? true : false;
    }
  }

  // Dark Mode State
  const [ darkMode, setDarkMode ] = useState(darkModePreferences());

  // I'm not a total nerd I swear 🤣
  const message = !darkMode ? '%cI see you have chosen to join the %cDark Side' : '%cMay the force be with %cYou'

  const lightsaber =`

  ${message}
    
  %c _____[==]___--_ %c~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ 
  %c( ==  |  |. .| { %c======================================> )  
  %c ------------__- %c~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~
  
  `;

  const saberColour = `color: ${!darkMode ? 'red' : 'green'}`;

  const starWarsMessage = () => {
    if (allowConsoleClear) console.clear();
    loadMessagePost();
    console.log(lightsaber, 'font-weight:bold', saberColour, 'font-weight:bold', saberColour, 'font-weight:bold', saberColour, 'font-weight:bold', saberColour);
  }

  // Dark mode value set with a fun message
  const updateDarkMode = (value) => {
    setDarkMode(value);
    localStorage.setItem('darkMode', value);
    starWarsMessage();
  }

  // Watches for preference Change and updates
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const selectedDarkMode = event.matches;
    if ( darkMode !== selectedDarkMode ) {
      updateDarkMode(selectedDarkMode);
    }
  });

  // Dark mode toggle
  const toggleDarkMode = () => {
    updateDarkMode(!darkMode);
  }

  const theme = "blue-theme"
  // const theme = darkMode ? "blue-theme" : "green-theme"

  const dark = "#111111";
  const light = "#ffff"

  document.documentElement.style.backgroundColor = darkMode ? dark : light;
  document.body.style.backgroundColor = darkMode ? dark : light;

  document.querySelector('meta[name="theme-color"]').setAttribute("content", darkMode ? dark : light);

  return <div className={`main-wrapper ${darkMode ? `dark-mode` : ``} ${theme}`}>
    <div onClick={toggleDarkMode}>
      <DarkModeToggle darkMode={darkMode}/>
    </div>
    <Intro/>
    <About/>
    <Projects/>
    <Footer/>
  </div>
}

export default App