import './magicPicture.scss';

const MagicPicture = ({
  src,
  fallback,
  type = 'image/webp',
  alt,
  ...delegated
}) => {
  return (
    <picture className='magic-picture'>
        <source srcSet={src} type={type} />
        <source srcset={fallback} type="image/jpeg" />
        <img className='magic-picture-img' src={fallback} alt={alt || ''} {...delegated} />
    </picture>
  );
};

export default MagicPicture
