// I'll Update this later to use a cms of some sort

// import Link from '../img/icons/link.svg';
// import Github from '../img/icons/github.svg';
// import Twitter from '../img/icons/twitter.svg';
// import Linkedin from '../img/icons/linkedin.svg';

const projects = [
  {
    title: 'Ally',
    description:
      'Ally was created over 3 days during the Entain Connecathon 2022 and ended up winning the women in digital stream. Ally Supports women to build confidence through sharing experiences, educating others and paving the way for open conversations and enabling allies.',
    image: 'Ally',
    imageAlt: 'Ally project preview image with entain 1st place badge',
    tags: ['Hackathon', 'React', 'Chackra UI', 'Scss'],
    date: '21/07/2022',
    links: [
      {
        title: 'Linkedin Post',
        icon: 'Linkedin',
        url: 'https://www.linkedin.com/posts/alexleybourne_connectathon2022-activity-6958667816728240128-Bqym?utm_source=share&utm_medium=member_desktop',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://entain-ally.web.app/',
      },
    ],
  },
  {
    title: `Jett Candy's Portfolio`,
    description:
      'Wanting a custom portfolio and to learn some code himself, Jett contacted me to build a portfolio whilst also mentoring him. The end result is stunning and built completely with raw html, javascript and css. This also allowed for the website to load super fast and  beat his previous sites performance. ',
    image: `Jett-Candy's-Portfolio`,
    imageAlt: `Preview image of Jett Cand's portfolio`,
    tags: ['Html', 'Javascript', 'Css', 'Api'],
    date: '05/10/2020',
    links: [
      {
        title: 'Twitter Post',
        icon: 'Twitter',
        url: 'https://twitter.com/AlexLeybourne/status/1316141664612696065?s=20&t=ogFIGmaHB9whjItbVcPvYw',
      },
    ],
  },
  {
    title: 'ML Template',
    description:
      'I love learning about AI and Machine Learning. One of the easiest ways to start a machine learning project is with Googles teachable machine. So I made a nice template to show off your models in style and made a demo Dog or Cat project.',
    image: 'ML-Template',
    imageAlt: 'Preview of the ML Template website',
    tags: ['Machine Learning', 'Javascript', 'Html', 'Css', 'Api'],
    date: '05/10/2020',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/MachineLearning',
      },
      {
        title: 'Twitter Post',
        icon: 'Twitter',
        url: 'https://twitter.com/AlexLeybourne/status/1316328646827630594?s=20&t=ogFIGmaHB9whjItbVcPvYw',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://alexleybourne.github.io/MachineLearning/',
      },
    ],
  },
  {
    title: 'Bill Gates Meme Maker',
    description:
      'This project came to be because I wanted to make a funny way for people to share their favourite languages and what they are learning whilst also playing with html canvas',
    image: 'Bill-Gates-Meme-Maker',
    imageAlt: 'Bill gates laying on a desk with his computer',
    tags: ['Javascript', 'Html', 'Css', 'Canvas'],
    date: '30/04/2020',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/BillGatesMemeMaker',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://alexleybourne.github.io/BillGatesMemeMaker/',
      },
    ],
  },
  {
    title: 'IP ME',
    description:
      'This project checks your IP address and finds your location and any other information linked to your IP.',
    image: 'IP-ME',
    imageAlt: 'Preview of the IP ME website ui',
    tags: ['Javascript', 'Html', 'Css', 'Api'],
    date: '18/12/2019',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/IPme',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://alexleybourne.github.io/IPme/',
      },
    ],
  },
  {
    title: 'Scissors Paper Code',
    description:
      'I love animating the web and this was my first intro to it. I made a scissors paper rock style game with the popmotion javascript animation library.',
    image: 'Scissors-Paper-Code',
    imageAlt: 'Scissors Paper Code project preview',
    tags: ['Javascript', 'Html', 'Css', 'Animation'],
    date: '24/12/2019',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/ScissorsPaperCode',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://alexleybourne.github.io/ScissorsPaperCode/',
      },
    ],
  },
  {
    title: 'Innovation Pools Website',
    description:
      'Whilst studying I was asked if I could make a small website for a pool building company. I met with the client and quickly came up with a plan to make an eye catching and effective website.',
    image: 'Innovation-Pools',
    imageAlt: 'Preview of the innovation pool website',
    tags: ['Javascript', 'Html', 'Css'],
    date: '07/12/2019',
    links: [
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://www.innovationpools.com.au/',
      },
    ],
  },
  {
    title: 'Code Meetr',
    description:
      'Code Meetr was my fourth project at coder academy. This project was built to be a platform for code enthusiasts to easily find events and hackathons near them.',
    image: 'Code-Meetr',
    imageAlt: 'Preview of the Code Meetr website',
    tags: ['React', 'Ruby', 'Rails', 'Css', 'Coder Academy'],
    date: '30/10/2019',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/CodeMeetr',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://codemeetr.herokuapp.com/',
      },
    ],
  },
  {
    title: 'My First Portfolio',
    description:
      'This is my original portfolio built with pure html and css for one of my assignments at Coder Academy.',
    image: 'My-First-Portfolio',
    imageAlt: 'A Photo of Alex Leybourne from his first portfolio',
    tags: ['Javascript', 'Html', 'Css', 'Coder Academy'],
    date: '13/09/2019',
    links: [
      {
        title: 'Github Project',
        icon: 'Github',
        url: 'https://github.com/alexleybourne/Portfolio',
      },
      {
        title: 'Link',
        icon: 'Link',
        url: 'https://alexleybourne.github.io/Portfolio/',
      },
    ],
  },
  {
    title: `#300 Days of Code`,
    description:
      'Starting my studies at Coder Academy I was introduced to twitter, where I found a great dev community and discovered #100DaysCode. I decided to post my journey and also use it as a journal as I progressed. I soon completed 100 days straight and then 200 and then 300 days!',
    image: '300-Days-Of-Code',
    imageAlt: `Alex Leybourne's tweets showing his 300 days of code journey`,
    tags: ['#100DaysOfCode'],
    date: '12/09/2019',
    links: [
      {
        title: 'Twitter Post',
        icon: 'Twitter',
        url: 'https://twitter.com/AlexLeybourne/status/1280048029223882753?s=20&t=ogFIGmaHB9whjItbVcPvYw',
      },
    ],
  },
];

export default function getProjects() {
  return projects;
}
