import './projectStack.scss';

import IconLink from '../../components/iconLink/IconLink'
import MagicPicture from '../../components/magicPicture/MagicPicture';
import getProjects from '../../data/projects';
import { motion } from 'framer-motion';
import rightButton from '../../img/icons/arrow-right.svg'
import { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const CardStack = () => {
    const projects = getProjects();
    const [cards, setCards] = useState(projects);
    const [currentCard, setCurrentCard] = useState(1);

    const { width } = useWindowDimensions();
    const isMobile = width < 901;

    const offsetTop = 10;
    const offsetLeft = isMobile ? 10 : 14;
    const cardScale = 0.06;

    const imageRoute = 'img/projects/';

    const moveToEnd = () => {
        // Project x of x logic
        setCurrentCard(currentCard !== cards.length ? currentCard + 1 : 1)

        const newOrder = cards;
        newOrder.push(newOrder.shift());
        setCards(newOrder);
    };

    // const moveBack = () => {
    //     // Project x of x logic
    //    setCurrentCard(currentCard !== 1 ? currentCard - 1 : cards.length)

    //     const newOrder = cards;
    //     newOrder.push(newOrder.shift());
    //     setCards(newOrder);
    // };

    return (
        <div className='project-wrapper'>
            <div className='wrapper-style'>
                <ul className='card-wrap-style'>
                {cards.map((item, index) => {
                    const canDrag = index === 0;

                    return (
                    <motion.li
                        key={item.title}
                        className='card-style dark-mode-background'
                        style={{
                        cursor: canDrag ? 'grab' : 'auto',
                        }}
                        animate={{
                        top: index * offsetTop * 3,
                        left: index * offsetLeft * 3,
                        scale: 1 - index * cardScale,
                        zIndex: projects.length - index,
                        }}
                        drag={canDrag ? 'x' : false}
                        dragConstraints={{
                        left: 0,
                        right: 0,
                        }}
                        onDragEnd={() => moveToEnd()}
                    >
                        <motion.div
                            className="card-inner"
                            animate={{
                                opacity: index === 0 ? 1 : 0
                            }}
                        >
                            <div className="dot-wrapper dark-mode-background">
                                <div className="dot theme-colour-background"/>
                                <div className="dot theme-colour-background"/>
                                <div className="dot theme-colour-background"/>
                            </div>
                            {/* <img src={`${imageRoute}${item.image}.webp`} alt={item.imageAlt} /> */}
                            <MagicPicture
                                src={`${imageRoute}${item.image}.webp`}
                                fallback={`${imageRoute}${item.image}.png`}
                                alt={item.imageAlt}
                            />
                            <div className="text">
                                <h1>
                                    {item.title}
                                </h1>
                                <p className="description">
                                    {item.description}
                                </p>
                                <p className="links-wrapper">
                                    {item.links.map((link, index) =>
                                        <IconLink key={index} link={link} />
                                    )}
                                </p>
                            </div>
                            {/* <div className="date-tags-wrapper">
                                <p className="date">
                                    {item.date}
                                </p>
                                <p className="tags-wrapper">
                                    {item.tags.map((tag, index) =>
                                        <div
                                            key={index}
                                            className="tag"
                                        >
                                            {tag}
                                        </div>
                                    )}
                                </p>
                            </div> */}
                            <div className="projct-buttons-wrapper">
                                <button
                                    title="Next"
                                    className="project-nav-button"
                                    onClick={() => moveToEnd()}
                                >
                                    <img className="invert-for-dark-mode" src={rightButton} alt="Next" />
                                </button>
                            </div>
                        </motion.div>
                    </motion.li>
                    );
                })}
                </ul>
            </div>
            <div className='bottom-info'>
                <p className='project-number'>
                <span>Project {currentCard} of {cards.length}</span>
                </p>
                {/* <div className='bottom-message'>
                    <p>Want to hear more about my projects and current work?</p>
                    <p>send me a message</p>
                </div> */}
            </div> 
        </div>
    );
};

export default CardStack;
